// IMPORTS
import gsap from 'gsap';
import SplitType from 'split-type';

// VARS
const mm = gsap.matchMedia();
const splits = SplitType.create('h1');
const heading = gsap.utils.toArray('h1');
const imgContainer = document.querySelector('.c-coming-soon__img-container');

// SETTERS
gsap.set(".c-coming-soon__img:not(.c-coming-soon__img--intro)", {
    autoAlpha: 0
});

gsap.set("html, body", {
    overflow: 'hidden'
});

gsap.set(splits.chars, {
    yPercent: 50,
    opacity: 0
});

// ANIMATIONS
gsap.from(".c-coming-soon__letter", 0.8, {
    yPercent: -30,
    opacity: 0,
    ease: "power3.inOut",
    stagger: 0.1,
});

gsap.to(".c-coming-soon__letter--top-left, .c-coming-soon__letter--top-right", 2, {
    top: 0,
    y: 0,
    ease: "power3.inOut",
    delay: 2,
});

gsap.to(".c-coming-soon__letter--bottom-right", 2, {
    bottom: 0,
    y: 0,
    ease: "power3.inOut",
    delay: 2,
});

gsap.to(".c-coming-soon__letter--top-left", 2, {
    x: 0,
    left: 0,
    ease: "power3.inOut",
    delay: 4,
});

gsap.to(".c-coming-soon__letter--top-right", 2, {
    x: 0,
    right: 0,
    ease: "power3.inOut",
    delay: 4,
});

gsap.to(".c-coming-soon__letter--bottom-right", 2, {
    x: 0,
    right: 0,
    ease: "power3.inOut",
    delay: 4,
});

gsap.to(".c-coming-soon__block--left", 2, {
    left: "-50%",
    ease: "power3.inOut",
    delay: 4,
});

gsap.to(".c-coming-soon__block--right", 2, {
    right: "-50%",
    ease: "power3.inOut",
    delay: 4,
});

gsap.to("html, body", {
    overflow: '',
    delay: 4
});

gsap.from(".c-coming-soon__img--intro", 2, {
    y: "110%",
    ease: 'Expo.easeInOut',
    delay: 4.2,
    stagger: 0.4
});

gsap.to(".c-coming-soon__img-container", 1.5, {
    bottom: 0,
    scale: .8,
    opacity: .75,
    ease: 'Expo.easeInOut',
    delay: 8,
});

gsap.to(".c-coming-soon__letter", 2, {
    opacity: .2,
    ease: "power3.inOut",
    delay: 8,
});

gsap.to(".c-coming-soon__letter--bottom-right", 2, {
    opacity: .2,
    ease: "power3.inOut",
    delay: 8,
});

gsap.from(".c-coming-soon__info > *", 2, {
    opacity: 0,
    y: 15,
    ease: 'Expo.easeInOut',
    delay: 9,
    stagger: 0.1
});

heading.forEach((e) => gsap.to(e.querySelectorAll('.char'), {
    duration: 2,
    opacity: 1,
    yPercent: 0,
    ease: "power4",
    stagger: 0.05,
    delay: 9
}));

let images = gsap.utils.toArray(".c-coming-soon__img"),
    tl = gsap.timeline({
        repeat: -1,
        delay: 12
    }),
    fadeDuration = 1.2,
    stayDuration = 2;

gsap.to(images.slice(1), {
    autoAlpha: 0,
    duration: fadeDuration - .2,
    delay: 9.5,
    stagger: .1
});

tl.to(images.slice(1), {
        delay: stayDuration,
        autoAlpha: 1,
        duration: fadeDuration,
        stagger: stayDuration + fadeDuration
    })
    .to(images.slice(0, images.length - 1), {
        autoAlpha: 0,
        duration: 0.01,
        stagger: stayDuration + fadeDuration
    }, stayDuration + fadeDuration)

    .set(images[0], {
        autoAlpha: 1
    })

    .to(images[images.length - 1], {
        autoAlpha: 0,
        duration: fadeDuration
    }, "+=" + stayDuration);

mm.add('(min-width: 768px)', () => {
    document.addEventListener("mousemove", mouseMoveFunc);

    function mouseMoveFunc(e) {
        const depth = 15;
        const moveX = (e.pageX - window.innerWidth / 2) / depth;
        const moveY = (e.pageY - window.innerHeight / 2) / depth;

        gsap.to(imgContainer, {
            x: moveX,
            y: moveY,
            ease: 'power2',
            duration: 1.5
        });
    }
}).add('(max-width: 768px)', () => {
    gsap.to(".c-coming-soon__img-container", 1.5, {
        top: 265,
        ease: 'Expo.easeInOut',
        delay: 8,
    });
    gsap.to(".c-coming-soon__letter--bottom-right", 2, {
        opacity: 0,
        ease: "power3.inOut",
        delay: 8,
    });
});